import Grid from '@mui/material/Grid';
import React from "react";
import axios from "axios";
import HomeKPITile from '../components/Home/HomeKPITile'

function Home() {
  const [dataLoading, setDataLoading] = React.useState(true);
  const [data, setData] = React.useState({});
  

  React.useEffect(() => {
    setDataLoading(true)
    axios.get(process.env.REACT_APP_API_URL+'/api/get_kpis').then((response) => {
      setDataLoading(false)
      var output = {};
      for (var i = 0; i < response.data.data.length; ++i)
        output[response.data.data[i]['kpi']] = response.data.data[i]['value'];
      setData(output);
    });
  }, []);

  return (
    <>
      <div class='page-wrapper' style={{'fontSize': '50px', 'textAlign': 'center'}}>
        Welcome to PCG Data Lake Sync Portal
      </div>
      <Grid container marginTop={0} spacing={2}>
        <HomeKPITile dataLoading={dataLoading} title='# Running Schedules' data={data} kpi={'num_running'} linkTo='monitoring'/>
        <HomeKPITile dataLoading={dataLoading} title='# Executions (Last 30 days)' data={data} kpi={'num_runs'} linkTo='monitoring'/>
        <HomeKPITile dataLoading={dataLoading} title='# Errors (Last 30 days)' data={data} kpi={'num_errors'} linkTo='monitoring'/>
        <HomeKPITile dataLoading={dataLoading} title='# Source Connections' data={data} kpi={'num_source_connections'} linkTo='sourceconnections'/>
        <HomeKPITile dataLoading={dataLoading} title='# Destination Connections' data={data} kpi={'num_destination_connections'} linkTo='destinationconnections'/>
        <HomeKPITile dataLoading={dataLoading} title='# Schedules' data={data} kpi={'num_schedules'} linkTo='schedules'/>

      </Grid>
    </>
  );
}

export default Home;
