import MonitoringTimeline from '../components/Monitoring/MonitoringTimeline'
import MonitoringTimelineTable from '../components/Monitoring/MonitoringTimelineTable'
import MonitoringLog from '../components/Monitoring/MonitoringLog'
import MonitoringTableList from '../components/Monitoring/MonitoringTableList';
import React from "react";
import axios from "axios";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

function Monitoring() {
  const [logDataLoading, setLogDataLoading] = React.useState(false);
  const [logData, setLogData] = React.useState([]);
  const [tableListDataLoading, setTableListDataLoading] = React.useState(false);
  const [tableListData, setTableListData] = React.useState([]);
  const [dayWindow, setDayWindow] = React.useState('7');
  const [viewType, setViewType] = React.useState('timeline');

  function loadLogData(runInstanceId) {
    setLogDataLoading(true)
    setTableListDataLoading(true)

    axios.get(process.env.REACT_APP_API_URL+'/api/logs?run_instance_id='+runInstanceId).then((response) => {
      setLogData(response.data.data)
      setLogDataLoading(false)
    });

    axios.get(process.env.REACT_APP_API_URL+'/api/table_log?run_instance_id='+runInstanceId).then((response) => {
      setTableListData(response.data.data)
      setTableListDataLoading(false)
    });
  }

  const handleDaysChange = (event) => {
    setDayWindow(event.target.value);
    setLogData([])
    setTableListData([])
  };

  const handleViewTypeChange = (event) => {
    setViewType(event.target.value);
    setLogData([])
    setTableListData([])
  };

  return (
    <div className='page-wrapper'>
      <h1>Monitoring</h1>
        <Box sx={{backgroundColor: 'white', padding: '10px'}}>
          <Grid container marginTop={2}>
            <Grid sx={{}} item lg={4} xl={4} md={4} xs={4}>
              <Grid container marginTop={0}>
                <Grid sx={{}} item lg={3} xl={3} md={3} xs={3}>
                  <Typography sx={{color: 'black', textAlign: 'center', paddingTop: '10px'}}>Filters</Typography>
                </Grid>
                <Grid sx={{}} item lg={8} xl={8} md={8} xs={8}>
                  <FormControl size="small">
                    <InputLabel sx={{}} id="days-select-label">Days</InputLabel>
                    <Select
                      labelId="days-select-label"
                      id="days-select"
                      value={dayWindow}
                      label="Days"
                      onChange={handleDaysChange}
                      sx={{width: '100px'}}
                    >
                      <MenuItem value={1}>1</MenuItem>
                      <MenuItem value={7}>7</MenuItem>
                      <MenuItem value={30}>30</MenuItem>
                      <MenuItem value={90}>90</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              
              </Grid>
              <Grid sx={{textAlign: 'center'}} item lg={4} xl={4} md={4} xs={4}>
              <ToggleButtonGroup
                color="primary"
                value={viewType}
                exclusive
                onChange={handleViewTypeChange}
                aria-label="Platform"
              >
                <ToggleButton value="timeline">Timeline</ToggleButton>
                <ToggleButton value="table">Details</ToggleButton>
              </ToggleButtonGroup>
              
              </Grid>
              <Grid sx={{}} item lg={4} xl={4} md={4} xs={4}>

              </Grid>
            </Grid>
          
        </Box>
      <div style={{'marginBottom': '10px', 'backgroundColor': '#f3f3f3'}}>
        {viewType === 'timeline' ? <MonitoringTimeline loadLogData={loadLogData} dayWindow={dayWindow}/>
        : <MonitoringTimelineTable loadLogData={loadLogData} dayWindow={dayWindow}/>
        }
      </div>
      <div style={{'marginBottom': '10px', 'backgroundColor': '#f3f3f3'}}>
        <MonitoringLog logDataLoading={logDataLoading} logData={logData}/>
      </div>
      <div style={{'backgroundColor': '#f3f3f3'}}>
        <MonitoringTableList tableListDataLoading={tableListDataLoading} tableListData={tableListData}/>
      </div>
    </div>
  );
}

export default Monitoring;