import React from "react";
import { DataGrid, GridToolbar  } from '@mui/x-data-grid';
import CircularProgress from '@mui/material/CircularProgress';

function MonitoringLog(props) {
  
  const columns = [
    { field: 'id', headerName: 'Log ID', width: 110 },
    { 
      field: 'log_date_time', 
      headerName: 'Log Date (UTC)', 
      width: 250, 
      renderCell: (params) => (<>{params.value}</>)
    },
    { field: 'log_level', headerName: 'Log Level', width: 100 },
    { field: 'log_message', headerName: 'Log Message', width: 1000 },
    { field: 'thread', headerName: 'Thread', width: 250 }
  ];
  
  return (
    <>
      {props.logData.length === 0 && !props.logDataLoading ? 
        <div style={{'color': 'black', 'textAlign': 'center'}}>Select a run from above to see the logs</div>
        : 
      props.logDataLoading ? <div style={{textAlign: 'center'}}><CircularProgress /></div> : 
      <div>
        <h2 style={{'color': 'black'}}>Logs</h2>
        <div style={{ height: 600, width: '100%' }}>
        <DataGrid
          rows={props.logData}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 25 },
            },
            sorting: {
              sortModel: [{ field: 'log_date_time', sort: 'asc' }],
            },
          }}
          pageSizeOptions={[25, 50, 100]}
          checkboxSelection={false}
          rowHeight={25}
          slots={{ toolbar: GridToolbar }}
        />
        </div>
        </div>
      }

    </>
  );
}

export default MonitoringLog;