import axios from "axios";
import React from "react";
import { DataGrid, GridToolbar  } from '@mui/x-data-grid';
import CircularProgress from '@mui/material/CircularProgress';



function MonitoringTimelineTable(props) {
  const [timelineData, setTimelineData] = React.useState([]);;
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    setIsLoading(true)
    axios.get(process.env.REACT_APP_API_URL+'/api/schedule_history?days='+props.dayWindow).then((response) => {
      var data = response.data.data;
      setTimelineData(data);
      setIsLoading(false)
    });
  }, [props.dayWindow,]);

  function str_pad_left(string, pad, length) {
    return (new Array(length + 1).join(pad) + string).slice(-length);
  }

  const handleRowClickEvent = (params, event, details) => {
    props.loadLogData(params.id);
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'schedule_name', headerName: 'Schedule Name', width: 400 },
    { field: 'start_date_time', headerName: 'Start Time (Local)', width: 200, valueFormatter: (params) => {
      var output_dt = eval('new '+params)
      return output_dt.toLocaleString()
    },  },
    { field: 'end_date_time', headerName: 'End Time (Local)', width: 200, valueFormatter: (params) => {
      var output_dt = eval('new '+params)
      return output_dt.toLocaleString()
    } },
    { field: 'status', headerName: 'Status', width: 100 },
    { field: 'run_time_seconds', headerName: 'Run Time (Minutes)', width: 200, valueFormatter: (params) => {
      const minutes = Math.floor(params / 60);
      const seconds = Math.round(params - (minutes * 60))
      return str_pad_left(minutes, '0', 2) + ':' + str_pad_left(seconds, '0', 2);
    } },
  ];

  return (
    <>
      {timelineData.length === 0 && !isLoading ? 
        <div></div>
        : 
        isLoading 
        ? 
        <div style={{textAlign: 'center'}}><CircularProgress /></div>
        : 
      <div>
        <h2 style={{'color': 'black'}}>Schedule Runs</h2>
        <div style={{ height: 400, width: '100%' }}>
        <DataGrid
            onRowClick={handleRowClickEvent}
            rows={timelineData}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { pageSize: 25 },
              }
            }}
            pageSizeOptions={[25, 50, 100]}
            columnVisibilityModel={{
              id: false
            }}
            rowHeight={25}
            slots={{ toolbar: GridToolbar }}
          />
        </div>
        </div>
      }

    </>
  );
}

export default MonitoringTimelineTable;