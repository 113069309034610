import { useState } from "react";
import { Box, Modal, TextField, Button, FormGroup, FormControlLabel, Checkbox, MenuItem } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close'

function ScheduleForm(props) {
    const style = {
        position: 'absolute',
        top: '40%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid black',
        boxShadow: 24,
        p: 1,
    }
                        

    return (
        <>
            <Modal
                open={props.open}
                onClose={props.handleClose}
            >
                <Box component="form" sx={style}>
                    <div style={{float: 'right'}}><Button sx={{minWidth: '30px'}} onClick={props.handleClose}><CloseIcon /></Button></div>
                    <Box sx={{p: 2}}>
                        <h2>{(props.selectedRecord === -1 ? 'New' : 'Edit')} Schedule</h2>
                        <TextField value={props.scheduleName} onChange={(event) => (props.setScheduleName(event.target.value))} margin='normal' required fullWidth label='Schedule Name' variant="outlined"></TextField>
                        <TextField select label='Source Connection' margin='normal' required style={{width: '100%', marginBottom: '20px'}} value={props.sourceConnectionID} onChange={(event) => {props.setSourceConnectionID(event.target.value)}} >
                        {Object.keys(props.sourceConnectionData).map((keyname, i) => (
                            <MenuItem value={props.sourceConnectionData[i].id}>{props.sourceConnectionData[i].name}</MenuItem>
                        ))}
                        </TextField>
                        <TextField select label='Destination Connection' margin='normal' required style={{width: '100%', marginBottom: '20px'}} value={props.destinationConnectionID} onChange={(event) => {props.setDestinationConnectionID(event.target.value)}} >
                        {Object.keys(props.destinationConnectionData).map((keyname, i) => (
                            <MenuItem value={props.destinationConnectionData[i].id}>{props.destinationConnectionData[i].name}</MenuItem>
                        ))}
                        </TextField>
                        

                        <TextField select label='Schedule Type' margin='normal' required style={{width: '100%', marginBottom: '20px'}} value={props.scheduleType} onChange={(event) => {props.setScheduleType(event.target.value)}}>
                            <MenuItem value={'DAILY'}>Daily</MenuItem>
                            <MenuItem value={'HOURLY'}>Hourly</MenuItem>
                            <MenuItem value={'MINUTE'}>Minutely</MenuItem>
                        </TextField>

                        Days of the Week
                        <FormGroup>
                            <FormControlLabel control={<Checkbox checked={props.sunday} onChange={(event) => {props.setSunday(event.target.checked)}} />} label="Sunday" />
                            <FormControlLabel control={<Checkbox checked={props.monday} onChange={(event) => {props.setMonday(event.target.checked)}} />} label="Monday" />
                            <FormControlLabel control={<Checkbox checked={props.tuesday} onChange={(event) => {props.setTuesday(event.target.checked)}} />} label="Tuesday" />
                            <FormControlLabel control={<Checkbox checked={props.wednesday} onChange={(event) => {props.setWednesday(event.target.checked)}} />} label="Wednesday" />
                            <FormControlLabel control={<Checkbox checked={props.thursday} onChange={(event) => {props.setThursday(event.target.checked)}} />} label="Thursday" />
                            <FormControlLabel control={<Checkbox checked={props.friday} onChange={(event) => {props.setFriday(event.target.checked)}} />} label="Friday" />
                            <FormControlLabel control={<Checkbox checked={props.saturday} onChange={(event) => {props.setSaturday(event.target.checked)}} />} label="Saturday" />
                        </FormGroup>
                    </Box>
                </Box>

            </Modal>
        </>
    )
}

export default ScheduleForm;