import DestinationConnectionTable from '../components/DestinationConnection/DestinationConnectionTable'

function DestinationConnections() {
  return (
    <div class='page-wrapper'>
      <h1>Destination Connections</h1>
      <div style={{'marginBottom': '10px', 'backgroundColor': '#f3f3f3'}}>
        <DestinationConnectionTable />
      </div>
    </div>
  );
}

export default DestinationConnections;
