import SourceConnectionTable from '../components/SourceConnection/SourceConnectionTable'

function SourceConnections() {
  return (
    <div className='page-wrapper'>
      <h1>Data Lake Connections</h1>
      <div style={{'marginBottom': '10px', 'backgroundColor': '#f3f3f3'}}>
        <SourceConnectionTable />
      </div>
    </div>
  );
}

export default SourceConnections;
