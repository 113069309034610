import axios from "axios";
import React from "react";
import { DataGrid, GridToolbar  } from '@mui/x-data-grid';

function SourceConnectionTable(props) {
  const [connectionData, setConnectionData] = React.useState([]);;
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    axios.get(process.env.REACT_APP_API_URL+'/api/source_connections').then((response) => {
      setConnectionData(response.data.data);
      setIsLoading(false)
    });
  }, []);


  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'created', headerName: 'Created', width: 170 },
    { field: 'type', headerName: 'Type', width: 140 },
    { field: 'name', headerName: 'Name', width: 200 },
    { field: 'ti', headerName: 'DL ti', width: 200 },
    { field: 'n', headerName: 'DL n', width: 200 },
    { field: 'dt', headerName: 'DL dt', width: 100 },
    { field: 'ci', headerName: 'DL ci', width: 250 },
    { field: 'cs', headerName: 'DL cs', width: 200 },
    { field: 'iu', headerName: 'DL iu', width: 200 },
    { field: 'pu', headerName: 'DL pu', width: 200 },
    { field: 'oa', headerName: 'DL oa', width: 200 },
    { field: 'ot', headerName: 'DL ot', width: 200 },
    { field: 'or', headerName: 'DL or', width: 200 },
    { field: 'sc', headerName: 'DL sc', width: 200 },
    { field: 'ev', headerName: 'DL ev', width: 200 },
    { field: 'v', headerName: 'DL v', width: 200 },
    { field: 'saak', headerName: 'DL saak', width: 200 },
    { field: 'sask', headerName: 'DL sask', width: 200 }
  ];


  
  return (
    <>
      {isLoading ? <div style={{'color': 'black'}}>Loading</div> : 
      <div>
      <div style={{ height: 400, width: '100%' }}>
      <DataGrid
          rows={connectionData}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 25 },
            },
          }}
          pageSizeOptions={[5, 10]}
          checkboxSelection
          columnVisibilityModel={{
            id: false,
            n: false,
            sc: false
          }}
          rowHeight={25}
          slots={{ toolbar: GridToolbar }}
        />
      </div>
      </div>
    }
    </>
  );
}

export default SourceConnectionTable;