import ScheduleTable from '../components/Schedule/ScheduleTable'
import ScheduleTableTable from '../components/Schedule/ScheduleTableTable';
import ScheduleForm from '../components/Schedule/ScheduleForm';
import React from "react";
import axios from "axios";

function Schedules() {
  const [scheduleData, setScheduleData] = React.useState([]);;
  const [scheduleDataisLoading, setScheduleDataIsLoading] = React.useState(true);
  const [scheduleTableDataLoading, setScheduleTableDataLoading] = React.useState(false);
  const [scheduleTableData, setScheduleTableData] = React.useState([]);
  const [selectedSchedule, setSelectedSchedule] = React.useState(null);
  const [scheduleEditOpen, setScheduleEditOpen] = React.useState(false);
  const [sourceConnectionData, setSourceConnectionData] = React.useState([]);;
  const [destinationConnectionData, setDestinationConnectionData] = React.useState([]);;

  const [scheduleName, setScheduleName] = React.useState('');
  const [sourceConnectionID, setSourceConnectionID] = React.useState(null);
  const [destinationConnectionID, setDestinationConnectionID] = React.useState(null);
  const [scheduleType, setScheduleType] = React.useState('DAILY');
  const [sunday, setSunday] = React.useState(true);
  const [monday, setMonday] = React.useState(true);
  const [tuesday, setTuesday] = React.useState(true);
  const [wednesday, setWednesday] = React.useState(true);
  const [thursday, setThursday] = React.useState(true);
  const [friday, setFriday] = React.useState(true);
  const [saturday, setSaturday] = React.useState(true);

  function loadScheduleTableData(scheduleID) {
    setScheduleTableDataLoading(true)
    setSelectedSchedule(scheduleID)
    
    axios.get(process.env.REACT_APP_API_URL+'/api/schedule_tables?schedule_id='+scheduleID).then((response) => {
      setScheduleTableData(response.data.data)
      setScheduleTableDataLoading(false)
    });

  }

  const handleFormClose = () => {
    setScheduleEditOpen(false)
  }
  
  React.useEffect(() => {
    axios.get(process.env.REACT_APP_API_URL+'/api/schedules').then((response) => {
      setScheduleData(response.data.data);
      setScheduleDataIsLoading(false)
    });
    axios.get(process.env.REACT_APP_API_URL+'/api/source_connections').then((response) => {
      setSourceConnectionData(response.data.data);
    });
    axios.get(process.env.REACT_APP_API_URL+'/api/destination_connections').then((response) => {
      setDestinationConnectionData(response.data.data);
    });
  }, []);

  return (
    <div class='page-wrapper'>
      <h1>Schedules</h1>
      <div style={{'marginBottom': '10px', 'backgroundColor': '#f3f3f3'}}>
        <ScheduleTable isLoading={scheduleDataisLoading} scheduleData={scheduleData} setScheduleEditOpen={setScheduleEditOpen} setSelectedSchedule={setSelectedSchedule} loadScheduleTableData={loadScheduleTableData} />
      </div>
      <div style={{'marginBottom': '10px', 'backgroundColor': '#f3f3f3'}}>
        <ScheduleTableTable selectedSchedule={selectedSchedule} isLoading={scheduleTableDataLoading} scheduleTableData={scheduleTableData} />
      </div>
      <ScheduleForm 
        open={scheduleEditOpen} 
        handleClose={handleFormClose} 
        scheduleName={scheduleName}
        setScheduleName={setScheduleName}
        sourceConnectionID={sourceConnectionID}
        setSourceConnectionID={setSourceConnectionID}
        destinationConnectionID={destinationConnectionID}
        setDestinationConnectionID={setDestinationConnectionID}
        sourceConnectionData={sourceConnectionData} 
        destinationConnectionData={destinationConnectionData} 
        scheduleType={scheduleType}
        setScheduleType={setScheduleType}
        sunday={sunday}
        setSunday={setSunday}
        monday={monday}
        setMonday={setMonday}
        tuesday={tuesday}
        setTuesday={setTuesday}
        wednesday={wednesday}
        setWednesday={setWednesday}
        thursday={thursday}
        setThursday={setThursday}
        friday={friday}
        setFriday={setFriday}
        saturday={saturday}
        setSaturday={setSaturday}
        
        />
    </div>
  );
}

export default Schedules;
