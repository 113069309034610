import axios from "axios";
import React from "react";
import { Chart } from "react-google-charts";
import CircularProgress from '@mui/material/CircularProgress';

function MonitoringTimeline(props) {
  const [timelineData, setTimelineData] = React.useState([]);;
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    setIsLoading(true)
    axios.get(process.env.REACT_APP_API_URL+'/api/schedule_history?days='+props.dayWindow).then((response) => {
      var columns = [
        { type: "string", id: "Tenant" },
        { type: "string", id: "Run Instance ID", role: "tooltip" },
        { type: "string", id: "Status" },
        { type: "date", id: "Start" },
        { type: "date", id: "End" }
      ];

      var data = [columns,]
      
      for (var i = 0; i < response.data.data.length; i++) {
        data.push([response.data.data[i]['tenant'], response.data.data[i]['id'], response.data.data[i]['status'], eval('new '+response.data.data[i]['start_date_time']), eval('new '+response.data.data[i]['end_date_time'])]);
      }
      
      setTimelineData(data);
      setIsLoading(false)
    });
  }, [props.dayWindow,]);

  
  return (
    <>
      {isLoading ? <div style={{textAlign: 'center'}}><CircularProgress /></div> : 
      <div style={{ height: 300, width: '100%' }}>
      <Chart
        chartType="Timeline"
        data={timelineData}
        width="100%"
        height="400px"
        legendToggle
        hAxis = {{
            format: "HH:mm"
        }}
        options = {{allowHtml: true}}
        chartEvents={[
            {
              eventName: "select",
              callback({ chartWrapper }) {
                props.loadLogData(timelineData[chartWrapper.getChart().getSelection()[0].row+1][1]);
              }
            }
          ]}
      />
      </div>
    }
    </>
  );
}

export default MonitoringTimeline;