import React from "react";
import { DataGrid, GridToolbar  } from '@mui/x-data-grid';
import CircularProgress from '@mui/material/CircularProgress';

function MonitoringTableList(props) {
  
  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'table_name', headerName: 'Table Name', width: 300 },
    { field: 'queryid', headerName: 'Data Lake Query ID', width: 300 },
    { field: 'rowcount', headerName: 'Row Count', width: 200 }
  ];

  return (
    <>
      {props.tableListData.length === 0 && !props.tableListDataLoading ? 
        <div></div>
        : 
      props.tableListDataLoading ? <div style={{textAlign: 'center'}}><CircularProgress /></div> : 
      <div>
        <h2 style={{'color': 'black'}}>Table List</h2>
        <div style={{ height: 400, width: '100%' }}>
          <DataGrid
            rows={props.tableListData}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { pageSize: 25 },
              },
              sorting: {
                sortModel: [{ field: 'rowcount', sort: 'desc' }],
              },
            }}
            pageSizeOptions={[25, 50, 100]}
            checkboxSelection={false}
            columnVisibilityModel={{
              id: false
            }}
            rowHeight={25}
            slots={{ toolbar: GridToolbar }}
          />
        </div>
        </div>
      }

    </>
  );
}

export default MonitoringTableList;