import axios from "axios";
import React from "react";
import { DataGrid, GridActionsCellItem, GridCheckCircleIcon, GridToolbar, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton  } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import { Button } from "@mui/material";

function EditToolbar(props) {
  const { setEditOpen, setSelectedRecord } = props
  const handleClick = () => {
    setEditOpen(true, -1)
    setSelectedRecord(-1)
  }
  return (
    <GridToolbarContainer>
      <Button sx={{display: 'none'}} color="primary" startIcon={<AddIcon />} onClick={handleClick}>Add record</Button>
      <GridToolbarExport />
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
    </GridToolbarContainer>
    
  )
}

function ScheduleTable(props) {
  

  const handleEditClick = (id) => () => {

  }

  const handleDeleteClick = (id) => () => {

  }

  


  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'schedule_name', headerName: 'Name', width: 300 },
    { field: 'created', headerName: 'Created', width: 170 },
    { field: 'source_connection', headerName: 'Source Connection', width: 250 },
    { field: 'destination_connection', headerName: 'Destination Connection', width: 250 },
    { field: 'time_interval_type', headerName: 'Time Interval Type', width: 150 },
    { field: 'time_interval', headerName: 'Time Interval', width: 100 },
    { field: 'start_time', headerName: 'Start Time', width: 120 },
    { field: 'end_time', headerName: 'End Time', width: 120 },
    { field: 'timezone', headerName: 'Timezone', width: 200 },
    { field: 'dow_sunday', headerName: 'Sun', width: 70, renderCell:({ row }) => (
      <>
        {(row.dow_sunday === true ? <CheckCircleIcon sx={{color: '#3e7a3e'}} /> : <CancelIcon sx={{color: '#7a3e3e'}} />)}
      </>
    ) },
    { field: 'dow_monday', headerName: 'Mon', width: 70 , renderCell:({ row }) => (
      <>
        {(row.dow_monday === true ? <CheckCircleIcon sx={{color: '#3e7a3e'}} /> : <CancelIcon sx={{color: '#7a3e3e'}} />)}
      </>
    ) },
    { field: 'dow_tuesday', headerName: 'Tue', width: 70 , renderCell:({ row }) => (
      <>
        {(row.dow_tuesday === true ? <CheckCircleIcon sx={{color: '#3e7a3e'}} /> : <CancelIcon sx={{color: '#7a3e3e'}} />)}
      </>
    ) },
    { field: 'dow_wednesday', headerName: 'Wed', width: 70 , renderCell:({ row }) => (
      <>
        {(row.dow_wednesday === true ? <CheckCircleIcon sx={{color: '#3e7a3e'}} /> : <CancelIcon sx={{color: '#7a3e3e'}} />)}
      </>
    ) },
    { field: 'dow_thursday', headerName: 'Thu', width: 70 , renderCell:({ row }) => (
      <>
        {(row.dow_thursday === true ? <CheckCircleIcon sx={{color: '#3e7a3e'}} /> : <CancelIcon sx={{color: '#7a3e3e'}} />)}
      </>
    ) },
    { field: 'dow_friday', headerName: 'Fri', width: 70 , renderCell:({ row }) => (
      <>
        {(row.dow_friday === true ? <CheckCircleIcon sx={{color: '#3e7a3e'}} /> : <CancelIcon sx={{color: '#7a3e3e'}} />)}
      </>
    ) },
    { field: 'dow_saturday', headerName: 'Sat', width: 70 , renderCell:({ row }) => (
      <>
        {(row.dow_saturday === true ? <CheckCircleIcon sx={{color: '#3e7a3e'}} /> : <CancelIcon sx={{color: '#7a3e3e'}} />)}
      </>
    ) },
    { field: 'actions', type: 'actions', headerName: 'Actions', hideable: false, width: 100, cellClassName: 'actions', getActions: ({ id }) => {
      return [
        <GridActionsCellItem 
          icon={<EditIcon />}
          label="Edit"
          className='textPrimary'
          onClick={handleEditClick(id)}
          color='inherit'
        />,
        <GridActionsCellItem 
          icon={<DeleteIcon />}
          label="Delete"
          className='textPrimary'
          onClick={handleDeleteClick(id)}
          color='inherit'
        />
      ]
    }}
    
  ];

  const handleRowClickEvent = (params, event, details) => {
    props.loadScheduleTableData(params.id);
  };
  
  return (
    <>
      {props.isLoading ? <div style={{'color': 'black'}}>Loading</div> : 
      
      <div>
        <div style={{ height: 400, width: '100%' }}>
        <DataGrid
            onRowClick={handleRowClickEvent}
            rows={props.scheduleData}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 25 },
              },
            }}
            pageSizeOptions={[5, 10]}
            checkboxSelection={false}
            columnVisibilityModel={{
              id: false,
              created: false
            }}
            rowHeight={25}
            slots={{ toolbar: EditToolbar }}
            slotProps={{
              toolbar: {setEditOpen: props.setScheduleEditOpen, setSelectedRecord: props.setSelectedSchedule}
            }}
          />
        </div>
      </div>
    }
    </>
  );
}

export default ScheduleTable;