import axios from "axios";
import React from "react";
import { DataGrid, GridToolbar  } from '@mui/x-data-grid';

function DestinationConnectionTable(props) {
  const [connectionData, setConnectionData] = React.useState([]);;
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    axios.get(process.env.REACT_APP_API_URL+'/api/destination_connections').then((response) => {
      setConnectionData(response.data.data);
      setIsLoading(false)
    });
  }, []);

  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'created', headerName: 'Created', width: 170 },
    { field: 'type', headerName: 'Type', width: 140 },
    { field: 'name', headerName: 'Name', width: 300 },
    { field: 'hostname', headerName: 'Hostname', width: 200 },
    { field: 'port', headerName: 'Port', width: 80 },
    { field: 'username', headerName: 'Username', width: 200 },
    { field: 'password', headerName: 'Password', width: 200,
        renderCell: (params) => (
          <>
            ********
          </>
        )
    },
    { field: 'database', headerName: 'Database', width: 200 },
    { field: 'schema', headerName: 'Schema', width: 200 }
  ];
  
  return (
    <>
      {isLoading ? <div style={{'color': 'black'}}>Loading</div> : 
      <div>
      <div style={{ height: 400, width: '100%' }}>
      <DataGrid
          rows={connectionData}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 25 },
            },
          }}
          pageSizeOptions={[5, 10]}
          checkboxSelection
          columnVisibilityModel={{
            id: false
          }}
          rowHeight={25}
          slots={{ toolbar: GridToolbar }}
        />
      </div>
      </div>
    }
    </>
  );
}

export default DestinationConnectionTable;