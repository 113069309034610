function About() {
  return (
    <div class='page-wrapper'>
      <h1>About</h1>
      <div style={{'marginBottom': '10px', 'backgroundColor': '#f3f3f3'}}>

      </div>
    </div>
  );
}

export default About;
