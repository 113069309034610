import React from "react";
import { DataGrid, GridActionsCellItem, GridToolbar, GridToolbarContainer, GridToolbarExport  } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import { Button } from "@mui/material";

function ScheduleTableTable(props) {
  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'created', headerName: 'Created', width: 170 },
    { field: 'tablename', headerName: 'Table Name', width: 200 },
    { field: 'last_refresh_date', headerName: 'Last Refresh Date', width: 250 },
    { field: 'query_override', headerName: 'Query Override', width: 350 },
    { field: 'variation_number', headerName: 'Variation Number Column', width: 200 },
    { field: 'additional_filters', headerName: 'Additional Filters', width: 350 },
    { field: 'actions', type: 'actions', headerName: 'Actions', hideable: false, width: 100, cellClassName: 'actions', getActions: ({ id }) => {
      return [
        <GridActionsCellItem 
          icon={<EditIcon />}
          label="Edit"
          className='textPrimary'
          onClick={handleEditClick(id)}
          color='inherit'
        />,
        <GridActionsCellItem 
          icon={<DeleteIcon />}
          label="Delete"
          className='textPrimary'
          onClick={handleDeleteClick(id)}
          color='inherit'
        />
      ]
    }}
    
  ];

  const handleEditClick = (id) => () => {

  }

  const handleDeleteClick = (id) => () => {

  }
 
  return (
    <>
      {props.isLoading ? <div style={{'color': 'black'}}>Loading</div> : 
        (!props.selectedSchedule || props.selectedSchedule === -1) && !props.isLoading ? 
          <div style={{'color': 'black', 'textAlign': 'center'}}>Select a run from above to see the logs</div> :
      <div>
        <div style={{ height: 800, width: '100%' }}>
        <DataGrid
            rows={props.scheduleTableData}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 25 },
              },
            }}
            pageSizeOptions={[5, 10]}
            checkboxSelection={false}
            columnVisibilityModel={{
              id: false,
              created: false
            }}
            rowHeight={25}
            slots={{ toolbar: GridToolbar }}
          />
        </div>
      </div>
    }
    </>
  );
}

export default ScheduleTableTable;