import React from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';

import CircularProgress from '@mui/material/CircularProgress';
import { Typography } from "@mui/material";

function HomeKPITile(props) {
  return (
    <Grid sx={{}} item lg={4} xl={4} md={4} xs={4}>
      <Box sx={{backgroundColor: '#00000087', color: 'white', borderRadius: '5px', padding: '10px', height: '200px'}}>
        <Typography>{props.title}</Typography>
        <Box sx={{marginTop: '30px'}}>
          {props.dataLoading ? <div style={{height: '100%', textAlign: 'center'}}><CircularProgress /></div> : 
          <Button component={Link} to={props.linkTo} sx={{color: 'white', width: '100%', textAlign: 'center', fontSize: '40px', fontWeight: 'bold'}}>{props.data[props.kpi]}</Button>
          }
        </Box>
        
      </Box>
    </Grid>
  );
}

export default HomeKPITile;