import { Routes, Route, Outlet, Link } from "react-router-dom";
import Home from './views/Home';
import SourceConnections from './views/SourceConnections';
import DestinationConnections from './views/DestinationConnections';
import Schedules from './views/Schedules';
import Monitoring from './views/Monitoring';
import Reports from './views/Reports';
import About from './views/About';
import NoMatch from './views/NoMatch';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


library.add(fas)

function App() {
  return (
    <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="sourceconnections" element={<SourceConnections />} />
          <Route path="destinationconnections" element={<DestinationConnections />} />
          <Route path="schedules" element={<Schedules />} />
          <Route path="monitoring" element={<Monitoring />} />
          <Route path="reports" element={<Reports />} />
          <Route path="about" element={<About />} />
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
  );
}

function Layout() {
  return (
    <div>
      <div class="container-fluid">
        <div class="row flex-nowrap">
          <div class="col-auto col-md-3 col-xl-2 px-sm-2 px-0 bg-dark">
            <div class="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
              <a href="/" class="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-white text-decoration-none">
                <span class="fs-5 d-none d-sm-inline">PCG Data Lake Sync Portal</span>
              </a>
              <ul className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start" id="menu">
                <li className="nav-item">
                  <Link to="/">
                    <FontAwesomeIcon icon="fa-solid fa-house" />Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/sourceconnections">
                    <FontAwesomeIcon icon="fa-solid fa-water" />Data Lake Connections
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/destinationconnections">
                    <FontAwesomeIcon icon="fa-solid fa-database" />Destination Connections
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/schedules">
                    <FontAwesomeIcon icon="fa-solid fa-calendar-days" />Schedules
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/monitoring">
                    <FontAwesomeIcon icon="fa-solid fa-eye" />Monitoring
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div style={{'flex': '1', 'minWidth': '0'}}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;

            